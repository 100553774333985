@import "../../global.scss";

.intro {
  background-color: white;

  .imgcontainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    // border: solid 30px black;

    @include mobile {
      flex-direction: column;
      justify-items: center;
    }

    .twoimgcontainer {
      display: flex;
      justify-content: center;
      border: solid 20px black;
      margin: 25px 25px 0 25px;
      z-index: 0;

      img {
        height: 100%;
        display: flex;
        justify-content: center;
   
        @include mobile {
          height: 0%;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .intro {
    flex-direction: column;
    align-items: center;
    
    .joshImg {
      height: 300px;
    }
  
  

  }
}
