.menu {
  width: 100vw;
  height: 20vh;
  background-color: black;
  color: white;
  position: fixed;
  top: 0px;
  top: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1.7s ease;

  &.active {
    top: 0;
  }

  .navIcons{
    display: flex;
    justify-content: center;
    padding-top: 35px;

    .navLink{
      font-size: 30px;
      text-decoration: none;
      color: white;
      margin-left: 15px;
    }

  }
}
