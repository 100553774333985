.portfolio {
  background-color: black;

  .gallery {
    display: flex;
    padding: 2px;
    transition: 0.3s;

    &:hover &__image {
      filter: grayscale(1);
    }

    &__column {
      display: flex;
      flex-direction: column;
      width: 25%;
    }

    &__link {
      margin: 2px;
      overflow: hidden;

      &:hover {
        .gallery__image {
          filter: grayscale(0);
        }

        .gallery__caption {
          opacity: 1;
        }
      }
    }

    &__thumb {
      position: relative;
    }

    &__image {
      display: block;
      width: 100%;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__caption {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 25px 15px 15px;
      width: 100%;
      font-family: "Raleway", sans-serif;
      font-size: 16px;
      color: white;
      opacity: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transition: 0.3s;
    }
  }
}
